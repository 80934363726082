import FuzzyListElement from '../fuzzy-list-element'
import {announce} from '@github-ui/aria-live'
import {fromEvent} from '@github-ui/subscription'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

function noticeHandler(event: Event) {
  if (!(event instanceof CustomEvent)) return

  const options = {} as {element?: HTMLElement}
  if (event.target instanceof FuzzyListElement) {
    options.element = event.target.ariaLiveElement
  }
  announce(`${event.detail} results found.`, options)
}

observe('fuzzy-list', {
  constructor: FuzzyListElement,
  subscribe: fuzzyList => fromEvent(fuzzyList, 'fuzzy-list-sorted', noticeHandler),
})
