// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {fromEvent} from '@github-ui/subscription'

observe('.js-code-scanning-autofix-page-reload', {
  subscribe: el =>
    fromEvent(el, 'socket:message', async function () {
      window.location.reload()
    }),
})
